<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="9">
                        <mdb-input label="成員名稱"
                                   v-model="admin.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">

                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="6">
                        <mdb-input label="成員帳號"
                                   v-show="admin.id==''"
                                   v-model="admin.account"></mdb-input>
                        <mdb-input label="成員帳號"
                                   v-show="admin.id!=''"
                                   v-model="admin.account"
                                   disabled></mdb-input>
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-input label="成員密碼"
                                   v-model="admin.password_text"></mdb-input>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4">
            <mdb-btn color="default"
                     @click="savedata">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
import { mdbCard, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
export default {
  props: { query: { id: "" } },
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      admin: {
        id: "",
        name: "",
        account: "",
        password_text: "",
      },
      ajaxing: false,
    };
  },
  mounted() {
    let vue = this;
    vue.admin.id = vue.query.id;
    console.clear();
    this.getData();
  },
  computed: {},
  methods: {
    getData() {
      let vue = this,
        query = [];
      if (vue.admin.id) {
        query.push({
          name: "admin",
          data: {
            id: {
              type: 0,
              value: vue.admin.id,
            },
          },
        });
      } else {
        query.push({
          name: "admin",
        });
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          console.info("res:", res.data.adminList);
          if (res.data.adminList) {
            for (let i in res.data.adminList[0]) {
              vue.admin[i] = res.data.adminList[0][i];
            }
          } else {
            for (let i in res.data.adminEmpty) {
              vue.admin[i] = res.data.adminEmpty[i] || "";
            }
          }
          vue.admin.password_text = "";
        });
    },
    savedata() {
      let vue = this,
        obj = {
          id: vue.admin.id,
          name: vue.admin.name,
          account: vue.admin.account,
        };
      if (vue.admin.password_text != "") {
        obj.password_text = vue.admin.password_text;
      }
      if (!vue.ajaxing) {
        vue.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "account/admin_save",
              data: {
                data: JSON.stringify(obj),
              },
            },
          })
          .then((res) => {
            vue.ajaxing = false;
            if (res.data.status == "ok") {
              vue.$swal.fire({
                icon: "success",
                title: "成功！",
                timer: 1500,
                showConfirmButton: false,
                willClose() {
                  if (res.data.id) {
                    vue.admin.id = res.data.id;
                    vue.$router.push("/account/edit/?id=" + res.data.id);
                  }
                  vue.getData();
                },
              });
            } else if (res.data.status == "error") {
              if (res.data.msg != "") {
                vue.$swal.fire({
                  icon: "error",
                  title: res.data.msg,
                  timer: 1500,
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
  },
};
</script>